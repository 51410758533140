<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
      @click="loadtype()"
    >
      Add Content
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Content"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="12" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="12">
                            <b-form-group label="Category">
                              <v-select
                                v-model="category"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                label="title"
                                :options="option"
                              />
                            </b-form-group>
                          </b-col>
                          <br /><br />
                          <b-col cols="12">
                            <b-form-group label="Content title">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="content_title"
                                  v-model="content_title"
                                  type="text"
                                  placeholder="name"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.content_title == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <br /><br />
                          <b-col cols="12">
                            <!-- image -->
                            <div v-if="content_Image == null">
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="
                                            require('@/assets/images/img/cloud-computing.png')
                                          "
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="อัพโหลดรูป"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <div class="pa-5" id="Box" v-else>
                              <b-row class="match-height">
                                <b-col>
                                  <b-container fluid class="p-1 bg-dark">
                                    <b-row>
                                      <b-col>
                                        <b-img
                                          thumbnail
                                          fluid
                                          :src="content_Image"
                                        />
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                  <b-form-file
                                    placeholder="เปลี่ยนรูปภาพ"
                                    ref="refInputEl"
                                    type="file"
                                    accept=".jpeg,.png,.jpg,GIF"
                                    :hidden="true"
                                    @change="onFileChange"
                                  />
                                </b-col>
                              </b-row>
                            </div>
                            <!-- image -->
                          </b-col>
                          <b-col cols="12">
                            <b-form-group label="Content Detail">
                              <quill-editor
                                v-model="content_detail"
                                :options="snowOption"
                              />
                            </b-form-group>
                          </b-col>
                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              Submit
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              type="reset"
                              variant="outline-secondary"
                              @click="reset()"
                            >
                              Reset
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  BFormFile,
  BImg,
  BMedia,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    BFormFile,
    BListGroupItem,
    BCardTitle,
    BImg,
    BMedia,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    quillEditor,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
            snowOption: {
        theme: 'snow',
      },
      content_Image: null,
      content_title: null,
      content_detail: null,
      check: {
        content_Image: false,
        content_title: false,
        content_detail: false,
      },
      category: "",
      option: [],
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {},
  methods: {
    async loadtype() {
      const params = {
        type: "type",
      };
      api
        .post("/contentgettype", params)
        .then((response) => {
          this.items = response.data.data;
          this.category = this.items[0].title;
          this.option = response.data.data;
        })
        .catch((error) => {});
    },
    onFileChange(e) {
      this.fileImage = e.target.files[0];
      console.log("fileImage", this.fileImage);
      const image = e.target.files[0];
      this.content_Image = URL.createObjectURL(image); ///show
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      if (this.content_title) {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            let formData = new FormData();
            if (this.category.title) {
              var category_data = this.category.title;
            } else {
              var category_data = this.category;
            }
            formData.append("file", this.fileImage);
            formData.append("content_title", this.content_title);
            formData.append("content_detail", this.content_detail);
            formData.append("category", category_data);
            console.log(this.fileImage);
            console.log(this.content_title);
            console.log(this.content_detail);
            console.log(this.category.title);
            api.post("/contentadd", formData).then((response) => {
              console.log("response", response);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.dialog1 = false;
                this.refresh();
                this.reset();
                  window.location.reload();
              }
            });
          }
        });
      } else {
        if (!this.content_title) {
          this.check.content_title = true;
          console.log("content_title null");
        }
        this.$swal({
          title: "Please complete the information.",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    reset() {
      this.content_Image = null;
      this.content_title = null;
      this.content_detail = null;
      this.category = "ทั่วไป";
    },
  },
};
</script>
